"use client";

import { signIn } from "@echosales/auth/react";

const GoogleAuthButton: React.FC = () => {
  return (
    <div
      className="cursor-pointer rounded-sm bg-black p-3 text-white"
      role="button"
      tabIndex={0}
      onClick={() => {
        void signIn("google");
      }}
    >
      Sign In Please
    </div>
  );
};

export default GoogleAuthButton;
